.page-view.home {
  @apply
    relative
    pb-10
    pt-0
    overflow-hidden;

  .loading-section,
  .error-section {
    @apply
      py-10
      text-center;

    @screen sm {
      @apply
        py-20;
    }

    .icon-wrapper {
      @apply
        bg-primary-lgray
        px-2
        py-1
        mb-4
        inline-block
        rounded;
    }

    h2 {
      @apply
        text-xl
        px-2;

      a {
        @apply
          font-serif
          text-xl
          underline
          text-primary-magenta;
      }

      @screen sm {
        @apply
          text-2xl;

        a {
          @apply
            text-2xl;
        }
      }
    }
  }

  .intro-home {
    @apply
      items-center
      text-center
      py-8;

    @screen lg {
      @apply
        pt-2
        pb-4;
        
      > * {
        @apply
          w-1/3;

        img {
          @apply
            w-80
            inline-block;
        }
      }
    }

    h3 {
      @apply
        text-2xl;

      i {
        @apply
          text-2xl
          not-italic
          text-primary-magenta;
      }

      @screen sm {
        @apply
          text-3xl;

        i {
          @apply
            text-3xl;
        }
      }

      a {
        @apply
          bg-primary-magenta
          rounded-full
          text-xs
          px-2
          py-1
          text-white
          basic-transition;

          &:hover {        
            box-shadow: 0 0 4px #DB003D;
          }
      }
    }
  }

  .main-img {
    @apply
      absolute
      top-1/2
      right-0
      transform
      -translate-y-1/2
      w-1/2
      -z-1
      hidden;

    @screen lg {
      @apply
        block;
    }

    @screen xl {
      @apply
        transform-none
        top-4
        bottom-0;

      img {
        @apply
          object-contain
          h-full
          w-full;
      }
    }
  }
}